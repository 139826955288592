<template>
  <div id="content-wrapper">
    <div class="container">
      <div class="row">
        <div class="col-sm-12">
          <div
            class="project-page mt-4 d-flex justify-content-center align-items-center"
          >
            <h2 class="m-0">Hashmasks Analytics</h2>
          </div>
          <div>
            <div class="d-flex justify-content-center mt-4">
              <div
                v-for="item in buttonItems"
                :key="item.value"
                v-on:click="handleSelect(item)"
                class="toggle-button lined thick inline-block analytics-toggle-button"
                :class="{
                  'toggle-button-select': item.value === selected.value,
                }"
              >
                <img
                  v-if="item.value === selected.value"
                  src="../../public/images/check.png"
                  class="toggle-button-select-img"
                />
                {{ item.name }}
              </div>
            </div>
            <div class="my-3 w-100 dune-iframe-container">
              <iframe class="dune-iframe" :src="selected.url" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script defer>
export default {
  name: 'NamesChart',
  data() {
    return {
      loading: true,
      selected: {},
      buttonItems: [
        {
          name: 'Name Changes per Day',
          value: '1',
          url:
            'https://dune.com/embeds/3372300/5657689/',
        },
        {
          name: 'Last Name Changes',
          value: '2',
          url:
            'https://dune.com/embeds/3372111/5657439/',
        },
        {
          name: 'NCT Burnt per Day',
          value: '4',
          url:
            'https://dune.com/embeds/3372318/5657712/',
        },
      ],
    };
  },
  mounted() {
    this.selected = this.buttonItems[0];
  },
  methods: {
    handleSelect(item) {
      this.selected = item;
    },
  },
};
</script>

<style></style>
